import { SubCouponStatus } from "./Enums";

// Sub coupons
export class SubCouponsFilter {
    code: string;
    status: SubCouponStatus | null;
    pageIndex: number;

    constructor(){
        this.code = '';
        this.status = null;
        this.pageIndex = 0;
    }
}